<template>
  <div>
    <h2>Other online tools</h2>
    <div class="grid-container">
      <div class="grid-item">
        <h3>Convert from PDF</h3>
        <ul>
          <li><a href="#">PDF to Word</a></li>
          <!-- add more sub-contents as needed -->
        </ul>
      </div>
      <div class="grid-item">
        <h3>Convert to PDF</h3>
        <ul>
          <li><a href="#">Word to PDF</a></li>
          <!-- add more sub-contents as needed -->
        </ul>
      </div>
      <div class="grid-item">
        <h3>View & Edit</h3>
        <ul>
          <li><a href="#">Edit PDF</a></li>
          <!-- add more sub-contents as needed -->
        </ul>
      </div>
      <div class="grid-item">
        <h3>Sign & Security</h3>
        <ul>
          <li><a href="#">eSign PDF</a></li>
          <!-- add more sub-contents as needed -->
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SummaryToolComponent",
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.grid-item {
  padding: 20px;
  border-radius: 5px;
}

.grid-item ul li {
  list-style-type: none;
  margin-bottom: 10px;
  padding: 5px;
}

.grid-item ul li:hover {
  background-color: #f5f5f5;
}

.grid-item ul li a {
  text-decoration: none;
}
</style>
