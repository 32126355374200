<template>
  <div>
    <div class="row" v-for="(row, index) in data" :key="index">
      <div class="column" v-for="(item, idx) in row" :key="idx">
        <img :src="item.image" alt="Image" />
        <h3>{{ item.title }}</h3>
        <p>{{ item.description }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KeyFeaturesComponent",
  data() {
    return {
      data: [
        [
          {
            image: "path/to/image1.jpg",
            title: "Vô hạn",
            description: "Mô tả cho tính năng vô hạn",
          },
          {
            image: "path/to/image2.jpg",
            title: "Chuyển đổi nhanh",
            description: "Mô tả cho tính năng chuyển đổi nhanh",
          },
          // Thêm các ô còn lại cho hàng đầu tiên nếu cần
        ],
        [
          {
            image: "path/to/image3.jpg",
            title: "Tính năng khác",
            description: "Mô tả cho tính năng khác",
          },
          {
            image: "path/to/image4.jpg",
            title: "Tính năng khác 2",
            description: "Mô tả cho tính năng khác 2",
          },
          // Thêm các ô còn lại cho hàng thứ hai nếu cần
        ],
      ],
    };
  },
};
</script>

<style scoped>
.row {
  display: flex;
}

.column {
  flex: 1;
  text-align: center;
}
</style>
