<template>
  <!-- <Menu /> -->
  <WordToPDFComponent />
  <SummaryToolComponent />
</template>

<script>
// import Menu from "./components/Menu.vue";
import WordToPDFComponent from "./components/WordToPdf.vue";
import SummaryToolComponent from "./components/SummaryTool.vue";

export default {
  name: "App",
  components: {
    // Menu,
    WordToPDFComponent,
    SummaryToolComponent,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
./components/WordToPdf.vue
