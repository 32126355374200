<template>
  <div>
    <h1>Word to PDF Converter</h1>
    <h2>Convert Word documents to PDF with amazing accuracy.</h2>
    <div id="dropzone" @dragover.prevent @drop="drop" @click="triggerFileInput">
      <img
        src="https://play-lh.googleusercontent.com/idKCZi5-SS5ShUYEIogcz8cAIwwE-rgLe6VFst83fupHqsYYWBrFkDqXnve3BVIER2o=w240-h480-rw"
        alt=""
        class="icon-image"
      />
      <input
        type="file"
        id="fileInput"
        ref="fileInput"
        @change="handleFileUpload"
        accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      />
      <label for="fileInput" class="file-label" @click.stop>
        <i class="fa fa-upload"></i> Choose File
      </label>
    </div>

    <KeyFeaturesComponent />

    <div class="info-section">
      <div class="info-image">
        <img
          src="https://s.smallpdf.com/static/d8104929aa24cf16.svg"
          alt="Info Image"
        />
      </div>
      <div class="info-text">
        <h2>How to Convert Word to PDF Online for Free:</h2>
        <ol>
          <li>Drag and drop any Microsoft Word document onto this page.</li>
          <li>
            Wait as this tool converts the uploaded Word file to PDF format.
          </li>
          <li>On the result page, preview and continue editing—if needed.</li>
          <li>Hit the “Download” button to save your PDF.</li>
        </ol>
      </div>
    </div>

    <h2>Frequently Asked Questions</h2>
    <div class="faq-item" v-for="(item, index) in faqItems" :key="index">
      <h3 @click="toggleAnswer(index)">
        {{ item.question }}
        <span class="expand-icon">{{ item.open ? "-" : "+" }}</span>
      </h3>
      <p v-show="item.open">{{ item.answer }}</p>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
import KeyFeaturesComponent from "./KeyFeatures.vue";

export default {
  name: "WordToPDFComponent",
  components: {
    KeyFeaturesComponent,
  },
  data() {
    return {
      faqItems: [
        { question: "Question 1", answer: "Answer to question 1", open: false },
        { question: "Question 2", answer: "Answer to question 2", open: false },
        // add more questions as needed
      ],
    };
  },
  methods: {
    // drop(event) {
    //   // ...
    // },
    // handleFileUpload(event) {
    //   // ...
    // },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    toggleAnswer(index) {
      this.faqItems[index].open = !this.faqItems[index].open;
    },
  },
};
</script>

<style scoped>
#dropzone {
  width: 90%;
  height: 250px;
  background: #f9f9f9;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  cursor: pointer;
}

#fileInput {
  display: none;
}

.file-label {
  background-color: #4caf50;
  border: none;
  color: white;
  padding: 10px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.file-label:hover {
  background-color: #45a049;
}

.icon-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin: 0 auto;
  display: block;
  border-radius: 5px;
  border: 2px solid #ccc;
  padding: 10px;
  background: #f9f9f9;
  margin-bottom: 10px;
}

.info-section {
  width: auto;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 5px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.info-image img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}

.info-text {
  width: 70%;
}

.info-text h3 {
  margin-bottom: 10px;
}

.info-text ol {
  padding-left: 20px;
}

.info-text ol li {
  margin-bottom: 10px;
  text-align: left;
}
</style>
