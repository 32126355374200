const state = {
  tasks: [],
};

const getters = {
  allTasks: (state) => state.tasks,
  completedTasks: (state) => state.tasks.filter((task) => task.completed),
  pendingTasks: (state) => state.tasks.filter((task) => !task.completed),
};

const actions = {
  addTask({ commit }, item) {
    commit("ADD_TASK", { content: item, completed: false });
  },
  updateTask({ commit }, item) {
    commit("UPDATE_TASK", item);
  },
};

const mutations = {
  ADD_TASK(state, item) {
    state.tasks.push(item);
  },
  UPDATE_TASK(state, item) {
    const index = state.tasks.findIndex(
      (task) => task.content === item.content
    );
    if (index !== -1) {
      state.tasks[index].completed = !state.tasks[index].completed;
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
